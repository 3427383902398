body {
    margin: 0 !important;
    height: 100%;
    display: block !important;
    background: white !important;
    color: black;
}

.renderer-components-field-content {
    max-width: 900px !important;
}

.renderer-components-field-wrapper .renderer-components-field-wrapper__content-wrapper .renderer-core-block-scroller {
    padding-top: 0 !important;
}

.renderer-components-block-custom-html {
    border: 2px solid;
    padding: 16px;
    margin-top: 32px;
}

.renderer-components-block-custom-html img {
    width: 100%;
    height: auto;
}


@media (min-width: 768px) {
    .thankyou-button {
        font-size: 20px;
        padding: 9px 20px 9px 23px;
    }
}

.thankyou-button {
    margin-top: 32px;
    position: relative;
    background: #005eb8;
    color: #fff;
    box-shadow: none !important;
    outline: none !important;
    z-index: 1;
    border-radius: 25px;
    border-width: 0;
    border-color: #000;
    border-style: solid;
    text-decoration: none;
}
